@use '../../styles/partials/typography' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/variables' as *;

.background {
  &__item {
    text-align: center;
    position: relative;
    @include size-project-container;
    box-shadow: 5px 5px 10px $color-box-shadow;
    @include flex(space-between, center, column, nowrap);

    &.experience,
    &.education {
      .tag {
        display: flex;
        justify-content: center; 
        align-items: center;
        min-width: 21rem;
        min-height: 3rem;
        margin-bottom: 0;
      }
    }
  }

  &__image-container {
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    width: 23rem;
    height: 23rem;
  }

  &__subheading {
    position: absolute;
    @include font-stack-projectCard-heading;
    top: 1rem;
    right: 35%;
  }

  &__image {
    width: 100%;
    height: 100%;
    background-color: white;
    padding-top: 1.5rem;
  }

  &__text-container {
    @include size-project-container;
    @include flex(flex-start, center, column, nowrap);
    @include font-stack-projectCard;
    padding: 1rem;
    border: 2px solid $purple-primary;
    position: absolute;
    top: 0;
    background-color: $bg-color-main;
    opacity: 0;
    @include transition;

    &:hover {
      opacity: 1;
    }

    @include desktop {
      &:hover {
        transform: scale(1.05);
      }
    }
  }

  &__title-text {
    @include font-stack-projectCard-heading;
    margin-bottom: 0.5rem;
  }

  &__techStack {
    width: 100%;
    @include flex(space-evenly, center, row, wrap);
    gap: 0.5rem;
  }
}
