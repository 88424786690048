@use '../partials/variables' as *;
@use '../partials/mixins' as *;

@font-face {
  font-family: 'Cormorant';
  src: url('../../assets/fonts/Cormorant-Bold.woff2') format('woff2'),
    url('../../assets/fonts/Cormorant-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cormorant';
  src: url('../../assets/fonts/Cormorant-SemiBold.woff2') format('woff2'),
    url('../../assets/fonts/Cormorant-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cormorant';
  src: url('../../assets/fonts/Cormorant-Regular.woff2') format('woff2'),
    url('../../assets/fonts/Cormorant-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@mixin font-stack(
  $font-style: normal,
  $font-size,
  $font-weight: 400,
  $line-height,
  $color: $text-color-primary,
  $letter-spacing: normal
) {
  font-style: $font-style;
  font-size: $font-size;
  font-weight: $font-weight;
  line-height: $line-height;
  color: $color;
  letter-spacing: $letter-spacing;
}

// Logo
@mixin font-stack-logo {
  @include font-stack(normal, 1.1rem, 600, 1.25rem, transparent, 0.15rem);
  background: $gradient-color;
  background-clip: text;
  text-transform: uppercase;

  @include tablet {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
}

// navBar
@mixin font-stack-navBar {
  @include font-stack(normal, 1.32rem, 400, 1rem, $bg-color-main, 0.15rem);
  text-transform: uppercase;

  @include tablet {
    @include font-stack(
      normal,
      0.875rem,
      600,
      1.375rem,
      $text-color-primary,
      0.2rem
    );
    margin-left: 1.5rem;
    padding-bottom: 0.3rem;
  }
}

// Heading section h1
@mixin font-stack-main-heading {
  @include font-stack(normal, 2rem, 700, 2.5rem, transparent);
  background: $gradient-color;
  background-clip: text;
}

// Body
@mixin font-stack-body {
  @include font-stack(
    normal,
    1.15rem,
    400,
    1.55rem,
    $text-color-primary,
    0.03rem
  );
}

@mixin font-stack-greeting {
  @include font-stack(normal, 1.35rem, 400, 2rem, $text-color-primary, 0.1rem);
}

@mixin font-stack-projectCard {
  @include font-stack(normal, 1.15rem, 400, 1.2rem, $text-color-primary);

  @include tablet {
    font-size: 1.25rem;
    line-height: 1.35rem;
  }
}

@mixin font-stack-projectCard-heading {
  @include font-stack(normal, 1.75rem, 700, 1.75rem, $purple-primary, 0.1rem);
}

@mixin font-stack-tag-mobile {
  @include font-stack(normal, 1.15rem, 400, 1.2rem, $purple-primary);
}

@mixin font-stack-button-mobile {
  @include font-stack(normal, 1rem, 400, 1rem, $bg-color-main, 0.1rem);
  text-transform: uppercase;

  @include desktop {
    &:hover {
      color: $text-color-primary;
    }
  }
}
