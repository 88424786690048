@use '../../styles/partials/typography' as *;
@use '../../styles/partials//mixins' as *;
@use '../../styles/partials/variables' as *;

.footer {
  @include padding-section;
  padding-top: 0;
  background-color: $bg-color-form-footer;
  text-align: center;

  @include tablet {
    padding-top: 0;
  }

  &__container-heart-icon {
    margin: 0 0.5rem;
  }

  &__icon-heart {
    color: $purple-primary;
    width: 1rem;
    height: 1rem;
  }
}
