@use '../../styles/partials/typography' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/variables' as *;

.background {
  background-color: $purple-primary;

  &__heading {
    color: $bg-color-main;
  }

  &__list {
    cursor: pointer;
    width: 100%;
    @include flex(space-between, center, column, nowrap);
    gap: 1.5rem;

    @include tablet {
      @include flex(center, center, row, wrap);
    }
  }
}
