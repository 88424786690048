@use '../../styles/partials/typography' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/variables' as *;

.projects {
  &__list {
    cursor: pointer;
    width: 100%;
    @include flex(space-between, center, column, nowrap);
    gap: 1.5rem;

    @include tablet {
      @include flex(center, center, row, wrap);
    }
  }
}
