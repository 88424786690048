@use '../../styles/partials/typography' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/variables' as *;

.projects {
  &__item,
  &__item-reverse {
    text-align: center;
    position: relative;
    @include size-project-container;
    box-shadow: 5px 5px 10px $color-box-shadow;
    @include flex(space-between, center, column, nowrap);
  }

  &__image {
    @include size-project-container;
  }

  &__text-container {
    @include size-project-container;
    @include flex(space-between, center, column, nowrap);
    @include font-stack-projectCard;
    padding: 1rem;
    border: 2px solid $purple-primary;
    position: absolute;
    top: 0;
    background: $bg-color-main;
    opacity: 0;
    @include transition;

    &:hover {
      opacity: 1;
    }

    @include desktop {
      &:hover {
        transform: scale(1.05);
      }
    }
  }

  &__title-text {
    @include font-stack-projectCard-heading;
  }

  &__description {
    margin: 0.5rem 0;
  }

  &__techStack {
    width: 100%;
    @include flex(space-evenly, center, row, wrap);
    gap: 0.5rem;
  }

  &__button-container {
    width: 100%;
    margin: auto;
    @include flex(space-between, center, row, nowrap);
    gap: 0.5rem;
  }
}
