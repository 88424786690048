@use '../../styles/partials/typography' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/variables' as *;

.aboutMe {
  @include flex(flex-start, center, column, nowrap);

  &__content,
  &__title {
    @include font-stack-main-heading;
    margin-bottom: 0.5rem;
  }

  &__description {
    @include flex(space-between, center, column, nowrap);

    @include tablet {
      align-items: flex-start;
      flex-direction: row;
    }
  }

  &__container-image {
    text-align: center;
    width: 100%;

    @include tablet {
      width: 50%;
    }
  }

  &__image {
    min-width: 12.5rem;
    min-height: 12.5rem;
    width: 50%;
    height: 50%;

    @include tablet {
      width: 70%;
      height: 70%;
    }
  }

  &__container-content {
    width: 100%;

    @include tablet {
      width: 50%;
    }
  }

  &__greeting {
    @include flex(space-between, center, column, nowrap);
  }

  &__content {
    @include flex(space-between, center, column, nowrap);
  }

  &__role {
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.25rem;
    text-align: center;
    margin-bottom: 1rem;

    @include tablet {
      font-size: 1.5rem;
    }
  }

  &__text {
    @include font-stack-body;
    text-align: center;
    margin-bottom: 1rem;

    @include desktop {
      margin-bottom: 2rem;
    }
  }

  &__container-button {
    @include flex(center, center, column, nowrap);
    gap: 1rem;

    @include desktop {
      gap: 2rem;
      flex-direction: row;
    }

    @include tablet {
      flex-direction: row;
    }
  }
}
