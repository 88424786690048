@use '../../styles/partials/typography' as *;
@use '../../styles/partials//mixins' as *;
@use '../../styles/partials/variables' as *;

.contact {
  background-color: $bg-color-form-footer;
  width: 100%;

  &__content {
    @include flex(space-between, center, column, nowrap);

    @include tablet {
      width: 90%;
      align-items: flex-start;
      flex-direction: row;
    }

    @include desktop {
      width: 70%;
    }
  }

  &__type-contact {
    @include flex(space-between, center, column, nowrap);
    width: 100%;

    @include tablet {
      width: 45%;
    }
  }

  &__list-icons {
    text-align: center;
    padding-bottom: 1rem;
    width: 50%;
    @include flex(space-between, center, row, nowrap);

    @include tablet {
      align-items: flex-start;
    }
  }

  &__item-icon {
    width: 100%;
    border-radius: 50%;
    @include transition;

    &:hover {
      transform: scale(1.1);
    }
  }

  &__message {
    display: none;

    @include tablet {
      display: flex;
      width: 18rem;
    }
  }

  &__link {
    color: $text-color-primary;
    width: 100%;
    height: 100%;
    min-width: 1.8rem;
    min-height: 1.8rem;
  }

  &__icon {
    min-width: 1.8rem;
    min-height: 1.8rem;
    @include transition;

    &:hover path,
    &:active path {
      fill: $purple-primary;
    }
  }

  &__greeting {
    display: none;

    @include tablet {
      display: block;
      margin-top: 1rem;
      @include font-stack-greeting;
    }
  }
}
