@use './styles/partials/typography' as *;
@use './styles/partials/mixins' as *;
@use './styles/partials/variables' as *;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 48px;
}

body {
  font-family: 'Cormorant', sans-serif;
  @include font-stack-body;
  background-color: $bg-color-main;
}

h1 {
  @include font-stack-main-heading;
  @include spaceAfterMainHeading;
}

header,
footer {
  background-color: $bg-color-form-footer;
}

section {
  @include padding-section;
  @include flex(flex-start, center, column, nowrap);
}

button,
input,
textarea {
  font-family: 'Cormorant', sans-serif;
}
