// Primary colors
$bg-color-main: #ffff; //
$bg-color-form-footer: #f4f5f5;
$text-color-primary: #474a56;
$color-box-shadow: #929aab; // box-shadow
$purple-primary: #9869c5;
$gradient-color: linear-gradient(
  90deg,
  rgba(71, 74, 86, 1) 0%,
  rgba(146, 154, 171, 1) 30%,
  rgba(152, 105, 197, 1) 60%,
  rgba(191, 162, 219, 1) 100%
);
$gradient-color-secondary: linear-gradient(
  126deg,
  rgba(152, 105, 197, 1) 0%,
  rgba(191, 162, 219, 1) 40%,
  rgba(146, 154, 171, 1) 70%,
  rgba(71, 74, 86, 1) 100%
);
// screen size
$desktop-breakpoint: 80rem; // 1280px
$tablet-breakpoint: 48rem; // 768px
