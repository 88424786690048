@use '../../styles/partials/typography' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/variables' as *;

.button-primary {
  @include buttonPrimaryStyle;

  @include tablet {
    margin: 1rem 0;
  }

  &__link {
    @include font-stack-button-mobile;
    width: 100%;
    @include flex(center, center, row, nowrap);
    padding: 0.8rem;
  }

  &__icon-button {
    margin-left: 0.5rem;
  }
}

.button-circle {
  @include buttonCircleStyle;

  &__link {
    @include font-stack-button-mobile;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    @include flex(center, center, row, nowrap);
    text-align: center;
  }

  &__icon-button {
    margin-left: 0.3rem;
  }
}
