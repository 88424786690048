@use '../partials/variables' as *;

// media queries
@mixin tablet {
  @media screen and (min-width: $tablet-breakpoint) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: $desktop-breakpoint) {
    @content;
  }
}

@mixin flex(
  $justify-content: flex-start,
  $align-items: stretch,
  $flex-direction: row,
  $flex-wrap: nowrap
) {
  display: flex;
  justify-content: $justify-content;
  align-items: $align-items;
  flex-direction: $flex-direction;
  flex-wrap: $flex-wrap;
}

// padding for all blocks
@mixin padding-header {
  padding: 0.5rem 1.5rem;

  @include tablet {
    padding: 0.5rem 2rem;
  }
}

@mixin padding-section {
  padding: 1.5rem;

  @include tablet {
    padding: 2rem;
  }
}

// space after main heading
@mixin spaceAfterMainHeading {
  margin-bottom: 1rem;

  @include tablet {
    margin-bottom: 2rem;
  }
}

@mixin buttonPrimaryStyle {
  width: 10rem;
  background-color: $purple-primary;
  text-align: center;
  border-radius: 5px;
  border: 1px solid $bg-color-form-footer;
  @include transition;

  @include desktop {
    width: 10rem;
    &:hover {
      background-color: $bg-color-form-footer;
      color: $text-color-primary;
      border-color: $text-color-primary;
    }
  }
  @include tablet {
    width: 10rem;
  }
}

@mixin buttonCircleStyle {
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  background: $purple-primary;
  background: $gradient-color-secondary;
  animation: size 3s ease infinite alternate;

  @keyframes size {
    0% {
      transform: scale(1.1);
    }

    100% {
      transform: scale(1);
    }
  }

  @include tablet {
    width: 7rem;
    height: 7rem;

    &:hover {
      filter: grayscale(0.5);
    }
  }

  @include desktop {
    width: 8rem;
    height: 8rem;
  }
}

@mixin size-icon {
  width: 2.5rem;
  height: 2.5rem;
}

@mixin size-project-container {
  border-radius: 5px;
  width: 23rem;
  height: 23rem;
}

@mixin transition {
  transition: all 0.5s ease-in-out;
}
