@use '../../styles/partials/typography' as *;
@use '../../styles/partials//mixins' as *;
@use '../../styles/partials/variables' as *;

.contact {
  &__form {
    @include flex(center, center, column, nowrap);
    background-color: $purple-primary;
    padding: 1rem;
    border-radius: 5px;
    width: 368px;
  }

  &__container-form {
    width: 100%;
    margin-bottom: 1rem;
  }

  input,
  textarea {
    width: 100%;
    resize: none;
    border-radius: 5px;
    @include font-stack-body;
    border: none;
    padding: 0.5rem;
    outline: none;
    background-color: $bg-color-form-footer;
  }

  &__submit-button {
    @include buttonPrimaryStyle;
    @include font-stack-button-mobile;
    padding: 0.8rem 0;
  }

  &__icon-send {
    vertical-align: middle;
    margin-left: 0.3rem;
  }
}
