@use '../../styles/partials/typography' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/variables' as *;

.header {
  position: sticky;
  top: 0;
  z-index: 1;
  @include padding-header;
  @include flex(space-between, center, row, nowrap);

  &__link-logo {
    @include flex(space-between, center, row, nowrap);
    width: 17rem;

    @include tablet {
      cursor: pointer;
      width: 18.5rem;
    }
  }

  &__icon {
    @include size-icon;
  }

  &__logo {
    @include font-stack-logo;
    margin: 0 0.5rem;
  }

  &__role {
    margin: 0.2rem 0 0 0.5rem;
    color: $text-color-primary;
    font-size: 0.7rem;
    line-height: 0.7rem;
  }

  &__navigation {
    @include flex(space-between, center, row, nowrap);
  }

  &__menu-icon {
    // width: 2.75rem;
    height: 3rem;
    @include flex(center, center, row, nowrap);

    @include tablet {
      display: none;
    }
  }

  &__ham-menu {
    color: $text-color-primary;
    opacity: 0.8;
    width: 30px;
    height: 30px;
  }

  &__nav-menu {
    padding: 0;
    display: none;
    width: 100%;
    height: 12.5rem;
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: 2;

    @include tablet {
      position: initial;
      height: auto;
      width: 65%;
      @include flex(space-between, center, row, nowrap);
    }

    &--active {
      width: 100%;
      height: 12.5rem;
      margin-top: 4rem;
      display: inline;
      padding: 1.5rem;
      background-color: $purple-primary;

      @include tablet {
        width: 65%;
        height: 3rem;
        display: flex;
        flex-direction: row;
        margin-top: 0rem;
        background-color: $bg-color-form-footer;
        padding: 0rem;
        box-shadow: none;
      }
    }
  }

  &__item {
    width: 100%;
    padding: 0 0.8rem 0.8rem 0.8rem;
    text-align: center;

    @include tablet {
      padding: 0;
    }
  }

  &__link-item {
    @include font-stack-navBar;
    @include transition;

    &:hover {
      @include tablet {
        color: $purple-primary;
        border-bottom: 2px solid $purple-primary;
      }
    }

    &--selected {
      border-bottom: 2px solid $bg-color-main;

      @include tablet {
        color: $purple-primary;
        border-bottom: 2px solid $purple-primary;
      }
    }
  }
}
